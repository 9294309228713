import { useRouter } from 'next/router';
import { useState } from 'react';
import ProductSmallTile from '../components/product-tile/product-small-tile';
import { server } from './../config';
import logo from './../public/CherryLogoWhite.png';
import styles from './index.module.css';
import hair from './../assets/hair.png';
import skin from './../assets/skin.png';
import beard from './../assets/beard.png';
import moroccanoil from './../assets/maroccanoil.png';

export default function Home() {

  const [randomProducts, setRandomProducts] = useState<any[] | null>(null);
  const [morocanoil, setMorocanoil] = useState<any[] | null>(null);
  const [kerastase, setKerastase] = useState<any[] | null>(null);
  const [dermalogica, setDermalogica] = useState<any[] | null>(null);
  const [beardProducts, setBeardProducts] = useState<any[] | null>(null);
  const [fetchedRandomProducts, setFetchedRandomProducts] = useState(false);

  const router = useRouter();
  if (!fetchedRandomProducts) {
    setFetchedRandomProducts(true);
    var funcs$ = [
      fetch(`${server}/api/category/getRandom?category=hudpleie`),
      fetch(`${server}/api/getFrontProducts`),
    ]
    Promise.all(funcs$).then(async (response) => {
      setFetchedRandomProducts(true);
      let randomProducts = await response[0].json();
      let frontProducts = await response[1].json();
      setMorocanoil(shuffleArray(frontProducts.filter((p: any) => p.brand === 'Moroccanoil')));
      setKerastase(shuffleArray(frontProducts.filter((p: any) => p.brand === 'Kérastase')));
      setDermalogica(shuffleArray(frontProducts.filter((p: any) => p.brand === 'Dermalogica')));
      setBeardProducts(shuffleArray(frontProducts.filter((p: any) => p.catalogName.indexOf("Mann") > -1)));
      setRandomProducts(shuffleArray(randomProducts));
    }).catch((error) => {
      console.error(error);
      setFetchedRandomProducts(true);
    });
  }

function shuffleArray(array : any[]) {
    for (var i = array.length - 1; i >= 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}  

  return (
    <div className={styles.container} style={{ zIndex: 1001, initialLetter: 1 }}>
      <main style={{marginTop: '16px'}}>
        <article className={styles.indexArticle}>
            <img src={logo.src} style={{height: '42px', float: 'left', margin: '8px'}} alt="Logo" className="showInMobile"/>
            <h1 className={styles.title} style={{marginTop: 0}}>Cherryblossom.no - <small>Hvordan fungerer det?</small></h1>
          <p>
            Å navigere i skjønnhetsverdenen kan være utfordrende med alle de ulike produktene og trendene. 
            Vi gjør det enklere for deg ved å samle et nøye utvalgt sortiment av skjønnhets- og kosmetikkprodukter
             fra flere leverandører på ett sted. Fra hudpleie til makeup - vi gir deg tilgang til det du trenger, 
             uten at du blir overveldet.
          </p>
          <p>
            Hvis flere butikker tilbyr samme produkt, viser vi deg hvor du kan få det til best pris. 
            Vi ønsker å gjøre skjønnhet enklere og mer tilgjengelig, så du kan fokusere på å føle deg bra.
          </p>
        </article> 
        <section>
          <h2>Populære kategorier</h2>
          <article className={styles.categoryContainer}>
            <div className={styles.category} onClick={() => router.push('produkter/haarpleie')}><div>Hårpleie</div><div id="hair"></div></div>
            <div className={styles.category} onClick={() => router.push('produkter/hudpleie')}><div>Hudpleie</div><div id="skin"></div></div>
            <div className={styles.category} onClick={() => router.push('produkter/moroccanoil')}><div>Moroccanoil</div><div id="moroccanoil"></div></div>
            <div className={`${styles.category}`} onClick={() => router.push('produkter/skjeggpleie')}><div>Skjeggpleie</div><div id="beard"></div></div>
          </article>
        </section>
        <br/>
        <div className="hideInMobile hideOnPad">
          
        </div>   
        <div className="showInMobile">
          
        </div>
        <h2>Dermalogica</h2>
        <section>
          {
            dermalogica?.map && dermalogica?.map(p =>
              <ProductSmallTile detailsPath={`/produkter/${p.brand}`} product={p} size='normal' key={p._id} />
            )
          }
        </section>            
        <h2>Kérastase</h2>
        <section>
          {
            kerastase?.map && kerastase?.map(p =>
              <ProductSmallTile detailsPath={`/produkter/${p.brand}`} product={p} size='normal' key={p._id} />
            )
          }
        </section>         
        <h2>Morocanoil</h2>
        <section>
          {
            morocanoil?.map && morocanoil?.map(p =>
              <ProductSmallTile detailsPath={`/produkter/${p.brand}`} product={p} size='normal' key={p._id} />
            )
          }
        </section>
        <h2>Skjeggpleie</h2>
        <section>
          {
            beardProducts?.map && beardProducts?.map(p =>
              <ProductSmallTile detailsPath={`/produkter/${p.brand}`} product={p} size='normal' key={p._id} />
            )
          }
        </section>          
        <h2>Utvalgte produkter</h2>
        <section>
          {
            randomProducts?.map && randomProducts?.map(p =>
              <ProductSmallTile detailsPath={`/produkter/${p.brand}`} product={p} size='normal' key={p._id} />
            )
          }
        </section>
        <div className="hideInMobile hideOnPad">
          <br/>
          <a href="https://go.blivakker.no/t/t?a=1794737614&as=1925297522&t=2&tk=1"><img src="https://track.adtraction.com/t/t?a=1794737614&as=1925297522&t=1&tk=1&i=1" width="980" height="120" alt="BliVakker"/></a>
        </div>                    
      </main>
      <br />
      <div className="showInMobile" style={{ marginTop: '32px' }}>
        <a href="https://go.blivakker.no/t/t?a=1794737850&as=1925297522&t=2&tk=1"><img src="https://track.adtraction.com/t/t?a=1794737850&as=1925297522&t=1&tk=1&i=1" width="300" height="251" alt="BliVakker" /></a>
      </div>     
        <style jsx>{`
        #hair {
          background-image: url('${hair.src}');
          background-position: top center;
          background-size: contain;
        }
        #skin {
          background-image: url('${skin.src}');
          background-position: top center;
          background-size: contain;
        }
        #moroccanoil {
          background-image: url('${moroccanoil.src}');
          background-position: top center;
          background-size: contain;
        }
        #beard {
          background-image: url('${beard.src}');
          background-position: top center;
          background-size: contain;
        }                                     
      `}</style>        
    </div>
    
  );
}